import "./App.css";
import React, { useEffect } from "react";
import Home from "./Pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navebar from "./Component/Navebar/Navebar";

function App() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <Router>
      <Navebar />
      <Routes>
        <Route exact path="/" element={<Home />} />

        {/* <Route path="/home" element={<Home/>}/> */}
        {/* <link to="/" element={< Home/>} /> */}
      </Routes>
    </Router>
  );
}

export default App;
