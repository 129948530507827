import React from "react";
import style from "./Home.module.css";
import MovingText from "react-moving-text";
import Card from "../Component/Card/Card";
import Button from "../Component/Button/Button";
import FooterOverlay from "../Component/Footer/Footer";
import welcome from "../assets/welcome.png";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";



export default function Home() {
  return (
    <div className={style.mainDiv}>
      {/* Content Start From Here */}

      <div className={style.topContainer}>
        <div className={style.topLeftContainer}>
          <MovingText
            type="fadeInFromBottom"
            duration="3000ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="2sec"
            fillMode="none"
          >
            <center id="home">
              {/* <div id={style.head}>Get Reward at your all purchase.</div> */}
              <br />
            </center>
          </MovingText>

          <MovingText
            type="fadeInFromBottom"
            duration="3000ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="2sec"
            fillMode="none"
          >
            <center>
              <div id={style.main_head}>
                Earn cash rewards on your daily purchases{" "}
              </div>
            </center>
          </MovingText>

          <MovingText
            type="fadeInFromBottom"
            duration="3000ms"
            delay="0s"
            direction="normal"
            timing="ease"
            iteration="2sec"
            fillMode="none"
          >
            <center id={style.span}>
              {/* <span>Cashback at every order</span>
              <br /> */}
              <span>Get Started Today</span>
            </center>
          </MovingText>

          <center>
            <a href="#"><Button name="Android App" /></a>
            <a href="#"><Button name="IOS App" /></a>
            {/* <Button name="IOS App" /> */}
          </center>
        </div>
        
     
        <ScrollAnimation
              animateIn="animate__fadeInRight"
              initiallyVisible={true}
              duration={1.8}
              animateOnce={true}
              className= {style.topRightContainer}
            >
          <img src={welcome} alt="welcome"/>
          </ScrollAnimation>
       
       
      </div>

      <Card
        key="1"
        id="work"
        fade="animate__fadeInRight"
        cardReverse="cardReverse"
        cardImageDirection="cardImageRight"
        title="Why Us?"
        subtitle="Browse Nearby Offers"
        description="Explore local businesses and discover the
        exciting rewards that you can earn when you shop."
        image="withdraw.png"
      />

      <Card
        key="2"
        fade="animate__fadeInLeft"
        cardImageDirection="cardImageLeft"
        subtitle="Upload Receipts And Earn Rewards"
        description="Once you upload a photo of your receipt and fill in the
        required information, you will be credited a reward onto your account."
        image="upload.png"
      />

      <Card
        key="3"
        fade="animate__fadeInRight"
        cardReverse="cardReverse"
        cardImageDirection="cardImageRight"
        subtitle="Send A Withdraw Request"
        description="When you are ready to claim your
        rewards, simply click the withdraw button and provide us with your preferred
        paypal email address."
        image="send.png"
      />


      <FooterOverlay />
    </div>
  );
}
