import React from "react";
import { FiFacebook, FiTwitter, FiInstagram } from "react-icons/fi";

// import { FooterOverlay, Newsletter } from '../../components';
import images from "../../assets/golden.png";
import spoon from "../../assets/spoon.png";
import "./Footer.css";

const privarcyPolicy =
  "https://firebasestorage.googleapis.com/v0/b/goldenbill-51874.appspot.com/o/static%2FPrivacy%20Policy%20Golden%20Bills.pdf?alt=media&token=27a23edd-3e97-41ae-a6cf-978e50400745";
const termAndCondition = "https://goldenbills.net/terms_condition.html";
const Footer = () => (
  <div className="app__footer section__padding" id="login">
    {/* <FooterOverlay />
    <Newsletter /> */}

    <div className="app__footer-links" id="contact">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contact Us</h1>
        {/* <p className="p__opensans">9 W 53rd St, New York, NY 10019, USA</p>
        <p className="p__opensans">+1 212-344-1230</p>
        <p className="p__opensans">+1 212-555-1230</p> */}
        <a href="#">info@goldenbills.net</a>
      </div>

      <div className="app__footer-links_logo">
        <img src={images} alt="footer_logo" />
        <p></p>
        {/* <p className="p__opensans">&quot;The best way to find yourself is to lose yourself in the service of others.&quot;</p> */}
        <img src={spoon} className="spoon__img" style={{ marginTop: 15 }} />
        <div className="app__footer-links_icons">
          <FiFacebook />
          <FiTwitter />
          <FiInstagram />
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Working Hours</h1>
        <p className="p__opensans">Monday-Friday:</p>
        <p className="p__opensans">08:00 am - 12:00 am</p>
        {/* <p className="p__opensans">Saturday-Sunday:</p>
        <p className="p__opensans">07:00 am - 11:00 pm</p> */}
      </div>
    </div>

    <div className="footer__copyright">
      <p className="p__opensans">
        {" "}
        &#169; 2022 Golden Bills LLC All rights reserved.
      </p>
      <center>
        <a
          href={termAndCondition}
          target="_blank"
          style={{ paddingBottom: "35px" }}
        >
          <b>Terms of Service</b>{" "}
        </a>
        and 
        <a
          href={privarcyPolicy}
          target="_blank"
          style={{ paddingBottom: "35px" }}
        >
          {" "}<b>Privacy Policy</b>{" "}
        </a>
      </center>
    </div>

    <div style={{ marginBottom: "30px" }}></div>
  </div>
);

export default Footer;
