import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import style from "./Navebar.module.css";

function Navebar() {

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`${style.topNav} ${scrollPosition > 50 ? style.topNavScrollView :""}`}>
        <li style={{ listStyle: "none" }}>
          <img
            style={{
              height: "50px",
              marginTop:"10px",
            }}
            src="app_icon.png"
            alt=""
          />{" "}
        </li>

        <div className={style.topNavRight}>
          <ul className={style.topNavLi}>
            <li>
              <a href="#home">Home </a>
            </li>
            <li>
              <a href="#work">Why Us? </a>
            </li>
            <li>
              <a href="#contact">Contact Us </a>
            </li>
            <li style={{ listStyle:"none"}}>
              <button className={style.btn}><a href="http://partner.goldenbills.net/" target={"_blank"}>Become a partner</a></button>
            </li>
          </ul>

           
            
           

        </div>
      </div>
    </>
  );
}

export default Navebar;