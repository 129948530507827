import React from "react";
import style from "./Card.module.css";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

function Card({
  title,
  subtitle,
  description,
  image,
  cardReverse,
  cardImageDirection,
  fade,
  id,
}) {
  return (
    <div className={style.container}>
      {title && (
        <p
          id={id}
          className={style.center}
        >
          {title}
        </p>
      )}

      <div className={`${style.card} ${cardReverse && style.cardReverse}`}>
      
         
            <ScrollAnimation
              animateIn={fade}
              initiallyVisible={false}
              duration={1.3}
              animateOnce={true}
              className={`${style.animationDiv} ${style.textContainer}`}
            >
              <h2 className={style.lefthead}> {subtitle}</h2>
              <p className={style.description}>{description}</p>
            </ScrollAnimation>
         

         <div className={
            `${style.imageContainer} ${cardReverse ?style.imageContainerStart :style.imageContainerEnd}`}>
         <img
            className={`${style.cardImage} ${
              cardImageDirection == "cardImageRight"
                ? style.cardImageRight
                : style.cardImageLeft
            }`}
            src={image}
          />
         </div>
      </div>
   
   
    </div>
  );
}
export default Card;
